<template>
  <div>
    <div v-if="selectedCategory && selectedCategory.uuid === $const.COUPON_UUID">
      <div v-if="$auth.check()">
        <div class="coupon-box">
          <div class="coupon-inner">
            <div class="input-box">
              <span>{{ $t('enter_coupon') }}</span>
              <input
                type="text"
                v-model="couponCode"
                :placeholder="$t('add_coupon_place_holder')"
                class="form-control"
              />
              <button class="btn-success btn" @click="redeemCode">{{ $t('add_coupon_button') }}</button>

              <span v-if="couponMessage" class="coupon-message">{{ couponMessage }}</span>
            </div>
          </div>
        </div>

        <div class="mycontainer-main">
          <div
            v-if="
              selectedCategory && selectedCategory.uuid === $const.COUPON_UUID
            "
          >
            <div class="clearfix"></div>

            <div class="main-content list-item-menu">
              <item-coupon
                v-for="item in items"
                :item="item"
                :cols="item.columnDisplay ? item.columnDisplay : columnDisplay"
                :key="genUniqueKey(item)"
                :uuid="
                  $route.params.uuid ? $route.params.uuid : $route.params.sub
                "
              ></item-coupon>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="coupon-nologin">
          <span v-html="$t('coupon_not_login')"></span>
          <a
            v-on:click.stop="redirectToUrl('/login/?returnUrl=/order/coupons')"
            class="button button--primary"
          >{{ $t('button_login') }}</a>
          <a
            v-on:click.stop="redirectToUrl('/register')"
            class="button button--primary register"
          >{{ $t('coupon_register') }}</a>
          <span v-html="$t('coupon_not_login_2')"></span>
        </div>
      </div>
    </div>

    <div
      v-if="selectedCategory && selectedCategory.uuid != $const.COUPON_UUID"
      class="mycontainer-main relative"
    >
      <div class="absolute mt-100 pt-100 w-full text-center z-100"
        v-if="loading && user_action === SELECT_CATEGORY">
        <img src="../assets/images/icons/loader-bike.gif" />
      </div>
      <div v-for="item in items" :key="genUniqueKey(item)">
        <div class="clearfix"></div>
        <h2
          v-if="
            item.single_type &&
              item.single_type.hasOwnProperty('is_default') &&
              !item.single_type.is_default
          "
          class="watermark desktop cond caps xs-my-20 xs-mx-10 xs-text-center heading-hr"
        >
          <img
            v-if="item.single_type.image && item.single_type.image.banner"
            :src="item.single_type.image.banner"
          />
          <span class="fs-30 pr-7" v-else>{{ item.single_type.name }}</span>
        </h2>
        <div
          v-if="
            item.single_type &&
              item.single_type.hasOwnProperty('is_default') &&
              !item.single_type.is_default
          "
          class="watermark mobile cond caps xs-my-20 xs-mx-10 xs-text-center"
        >
          <img
            v-if="item.single_type.image && item.single_type.image.banner"
            :src="item.single_type.image.banner"
          />
          <p class="fs-30 pr-7 pb-0" v-else>{{ item.single_type.name }}</p>
        </div>
        <div class="main-content list-item-menu">
          <item-combo
            v-for="menu in item.singles"
            :item="menu"
            :cols="menu.columnDisplay ? menu.columnDisplay : columnDisplay"
            :key="genUniqueKey(menu)"
            v-if="menu.is_combo"
          ></item-combo>
          <item-menu
            :item="menu"
            :cols="menu.columnDisplay ? menu.columnDisplay : columnDisplay"
            :key="genUniqueKey(menu)"
            :isHalf="menu.isHalf"
            :onlyLunch="menu.onlyLunch || null"
            :uuid="$route.params.uuid ? $route.params.uuid : $route.params.sub"
            v-else-if="
              (!menu.is_combo && $const.ITEM_VIEW_MODE == 0) || menu.isHalf
            "
          ></item-menu>
          <item-menu-one-tap
            v-bind:item="menu"
            v-bind:cols="
              menu.columnDisplay ? menu.columnDisplay : columnDisplay
            "
            :key="genUniqueKey(menu)"
            v-bind:uuid="$route.params.uuid"
            v-else-if="$const.ITEM_VIEW_MODE == 1"
            :is_hight_line="false"
          ></item-menu-one-tap>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { MAPPED_ACTIONS } from '../utils/map-actions'
import { mapGetters, mapActions, mapState } from 'vuex'
import ModalNotify from '../components/popup/ModalNotifyTakeawayLess.vue'

export default {
  components: {
    ItemMenu: () => import('@/components/menu/Item.vue'),
    ItemCombo: () => import('@/components/menu/ItemCombo.vue'),
    ItemCoupon: () => import('@/components/coupon/Item.vue'),
    ItemMenuOneTap: () => import('@/components/menu/ItemOneTap.vue')
  },
  data() {
    return {
      items: [],
      sizeCrust: [],
      extraCheese: [],
      categoryId: '',
      subCategoryId: '',
      hasHalf: false,
      slug: this.$route.params.slug,
      columnDisplay: 4,
      couponCode: '',
      couponMessage: '',
      isTakeawayLess: this.$disposition.getByKey(this, 'takeaWayLess', 0) == 1,
      ...MAPPED_ACTIONS
    }
  },
  created() {
    const body = document.getElementsByTagName('body')[0]
    body.classList.remove('mainpage-combo')
    body.id = 'page-category'
  },
  mounted() {
    this.$disposition.init(this)
    this.items = []

    this.initData()
    this.getColumnDisplay()
    this.$root.$on('onTrackingCategory', () => {
      this.trackpage()
      this.eventTrackingProductLoad(this, this.items, false, true)
    })

    this.$store.dispatch('setUserAction', this.SELECT_CATEGORY)

    if (this.isTakeawayLess) {
      this.$modal.show(ModalNotify, {}, {
        class: 'modal-menu',
        transition: 'nice-modal-fade',
        overlayTransition: 'overlay-fade',
        clickToClose: true,
        name: 'modal-notify'
      })
    }
  },
  beforeDestroy: function() {
    const body = document.getElementsByTagName('body')[0]
    body.id = ''
  },
  destroyed() {
    this.$root.$off('onTrackingCategory')
  },
  computed: {
    ...mapState(['loading', 'user_action']),
    ...mapGetters({
      selectedCategory: 'selectedCategory',
      selectedSubCategory: 'selectedSubCategory'
    })
  },
  watch: {
    loading: function(newValue, oldValue) {
      const saveDateTimeButtonElement = document.getElementsByClassName('save-date-time')[0]
      if (newValue) {
        if (saveDateTimeButtonElement) {
          saveDateTimeButtonElement.setAttribute('disabled', 'disabled')
        }
      } else {
        if (saveDateTimeButtonElement) {
          saveDateTimeButtonElement.removeAttribute('disabled')
        }
      }
    },
    selectedCategory: function(newValue, oldValue) {
      if (this.selectedCategory && this.selectedCategory.uuid) {
        this.$store.commit('setCategoryUuid', this.selectedCategory.uuid)

        if (
          !this.selectedCategory.lower_categories ||
          this.selectedCategory.lower_categories.length <= 0
        ) {
          this.$store.commit('setSubCategoryUuid', null)
          this.categoryId = this.selectedCategory.uuid
          this.subCategoryId = null

          this.getColumnDisplay()
          this.initData()
        }
      } else {
        this.$store.commit('setCategoryUuid', null)
        this.subCategoryId = null
      }
    },
    selectedSubCategory: function(newValue, oldValue) {
      if (this.selectedSubCategory && this.selectedSubCategory.uuid) {
        this.$store.commit('setSubCategoryUuid', this.selectedSubCategory.uuid)
        this.categoryId = this.selectedCategory.uuid
        this.subCategoryId = this.selectedSubCategory.uuid

        this.getColumnDisplay()
        this.initData()
      } else {
        this.$store.commit('setSubCategoryUuid', null)
        this.subCategoryId = null
      }
    }
  },
  methods: {
    ...mapActions({}),
    trackpage() {
      let category = {}
      let subcategory = {}
      let categories = this.$session.get('listCategory')
      if (categories && categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === this.selectedCategory.uuid) {
            category = categories[i]
            if (category.hasOwnProperty('lower_categories')) {
              for (let s in category.lower_categories) {
                if (this.subCategoryId === category.lower_categories[s].uuid) {
                  subcategory = category.lower_categories[s]
                  break
                }
              }
              if (
                this.empty(subcategory) &&
                category.hasOwnProperty('lower_categories') &&
                category.lower_categories.length > 0
              ) {
                subcategory = category.lower_categories[0]
              }
            }
            break
          }
        }
      }
      let name = category.slug
      if (!this.empty(subcategory)) {
        name += '.' + subcategory.slug
      }
      this.eventTrackingScreenLoad(this, {
        screen: { name: 'product-list.' + name, type: 'product-list' }
      })
    },
    getColumnDisplay() {
      if (this.selectedSubCategory && this.selectedSubCategory.uuid) {
        this.columnDisplay = this.selectedSubCategory.display_type.web
      } else if (this.selectedCategory && this.selectedCategory.uuid) {
        this.columnDisplay = this.selectedCategory.display_type
          ? this.selectedCategory.display_type.web
          : 4
      }
    },
    initData() {
      let vm = this
      let orderTime = this.convertDateTimeFromTimezoneToUTC(
        this.$disposition.getByKey(this, 'timezone', ''),
        this.$disposition.getByKey(this, 'collectionTimeValue', '')
      )
      if (!orderTime) {
        orderTime = moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      }

      if (this.selectedCategory && this.selectedCategory.uuid) {
        this.trackpage()
        vm.items = []

        if (this.selectedCategory.uuid === this.$const.COUPON_UUID) {
          if (this.$auth.check()) {
            let params = {}
            params['page'] = 1
            params['limit'] = 500
            params['active'] = 1

            this.axios.getCouponList(params, function(response) {
              vm.items = response.data.data.items || []
            })
          } else {
          }
        } else {
          let subCategoryId = this.selectedSubCategory
            ? this.selectedSubCategory.uuid
            : null
          let categoryId = this.selectedCategory
            ? this.selectedCategory.uuid
            : null
          let hasHalfHalf = false // make this true if you want half-half back
          let currentOutletCode = parseInt(vm.getOutletCode(vm))

          if (
            _.includes(vm.$const.HALF_HALF_EXCLUDE_STORE, currentOutletCode)
          ) {
            hasHalfHalf = false
          }

          let data = vm.getMenuRequest(categoryId, subCategoryId)
          data.single_type = 1
          data.lang = this.$session.get('lang') || this.$const.DEFAULT_LANGUAGE

          vm.axios.getMenuByCategory(data, function(response) {
            let items = response.data.data.items
            // MX only: set the default subcategory to last item.
            let defaultSubCategory = items.pop()
            items.push(defaultSubCategory)
            let category = vm.selectedSubCategory
              ? vm.selectedSubCategory
              : vm.selectedCategory

            if (
              hasHalfHalf &&
              (category.piece === vm.$const.HALF_HALF_ID &&
                category.only_lunch == vm.$const.HALF_HALF_SET.BOTH)
            ) {
              let halfItem = {
                single_type: {
                  image: {
                    banner: vm.$const.HALF_HALF_IMAGES.CATEGORY
                  },
                  is_default: false
                },
                singles: []
              }

              items.unshift(halfItem)
            }

            if (!items || !items.length) {
              items = []
              items.push({
                singles: []
              })
            }

            // PIZZA IS SELECTED (HALF & HALF)
            if (hasHalfHalf && category.piece === vm.$const.HALF_HALF_ID) {
              if (category.only_lunch === vm.$const.HALF_HALF_SET.LUNCH) {
                let half = getHalf(vm, category.only_lunch)
                items[0].singles.unshift(half)
              } else if (
                category.only_lunch === vm.$const.HALF_HALF_SET.NORMAL
              ) {
                let half = getHalf(vm, category.only_lunch)
                items[0].singles.unshift(half)
              } else if (category.only_lunch === vm.$const.HALF_HALF_SET.BOTH) {
                let half = getHalf(vm, category.only_lunch, 1)
                items[0].singles.unshift(half)

                half = getHalf(vm, category.only_lunch, 0)
                items[0].singles.unshift(half)
              }
            }

            vm.items = items
            // console.log(vm.items[0].singles.filter(i => i.id).map(i => i.id))

            // Add data for cart item
            vm.$cart.tier = data.tier
            vm.$cart.order_type = data.order_type
            vm.$cart.category_id = data.category_id
            // Add data for cart item
            vm.eventTrackingProductLoad(vm, vm.items, false, true)
            vm.$store.commit('setSelectedCategoryItems', items)
          })
        }
      }
    },
    getMinPrice(item) {
      let price = 0
      if (item.first_layers.length > 1) {
        for (let i = 0; i < item.first_layers.length; i++) {
          for (let j = 0; j < item.first_layers[i].second_layers.length; j++) {
            if (item.first_layers[i].second_layers[j].prices.length > 0) {
              if (i === 0) {
                price = this.formatNum(
                  item.first_layers[i].second_layers[j].prices[0].price
                )
              } else if (
                item.first_layers[i].second_layers[j].prices[0].price < price
              ) {
                price = this.formatNum(
                  item.first_layers[i].second_layers[j].prices[0].price
                )
              }
            }
          }
        }
      } else {
        price = this.formatNum(item.prices[0].price)
      }
      return price
    },
    redeemCode: function() {
      let vm = this
      vm.couponMessage = ''

      if (vm.couponCode) {
        vm.axios.apiCoupon.getRedeemCoupon(
          {
            code: vm.couponCode,
            isShowNotify: false
          },
          response => {
            if (response.data.status) {
              vm.$notify({
                type: 'success',
                title: vm.$t('coupon.redeem_success')
              })

              vm.initData()
              // vm.redirectToMenuPage(vm.$const.HEADER_MENU.COUPON, null, null, true)
              // vm.redirectToUrl('/order/coupons', true)
            } else {
              vm.couponMessage = vm.$t('coupon_attach.message_error')
            }
          },
          error => {
            vm.couponMessage = error.error.message
          }
        )
      } else {
        vm.couponMessage = vm.$t('coupon_attach.message_error')
      }
    }
  }
}

function getHalf(vm, onlyLunch, type = 0) {
  let image = ''
  let half = {}
  half.main_description = ''
  half.isHalf = true

  let category = vm.selectedSubCategory
    ? vm.selectedSubCategory
    : vm.selectedCategory
  if (category.only_lunch === vm.$const.HALF_HALF_SET.LUNCH) {
    half.uuid = vm.$const.HALF_HALF_LUNCH_UUID
    half.name = vm.$t('half_lunch_name')
    half.marketing_description = vm.$t('half_lunch_description')
    half.price = vm.$const.HALF_HALF_LUNCH_PRICE
    half.display_price = vm.$const.HALF_HALF_LUNCH_PRICE
    half.onlyLunch = vm.$const.HALF_HALF_SET.LUNCH

    image = vm.$const.HALF_HALF_IMAGES.LUNCH_4
    if (vm.columnDisplay === 2) {
      image = vm.$const.HALF_HALF_IMAGES.LUNCH_2
    }
  } else if (category.only_lunch === vm.$const.HALF_HALF_SET.NORMAL) {
    half.uuid = vm.$const.HALF_HALF_UUID
    half.name = vm.$t('btn_hatf_halt')
    half.marketing_description = vm.$t('half_description')
    half.price = vm.$const.HALF_HALF_PRICE
    half.display_price = vm.$const.HALF_HALF_PRICE
    half.onlyLunch = vm.$const.HALF_HALF_SET.NORMAL

    image = vm.$const.HALF_HALF_IMAGES.NORMAL_4
    if (vm.columnDisplay === 2) {
      image = vm.$const.HALF_HALF_IMAGES.NORMAL_2
    }
  } else if (category.only_lunch === vm.$const.HALF_HALF_SET.BOTH) {
    if (type === 0) {
      half.uuid = vm.$const.HALF_HALF_UUID
      half.name = vm.$t('btn_hatf_halt')
      half.marketing_description = vm.$t('half_description')
      half.price = vm.$const.HALF_HALF_PRICE
      half.display_price = vm.$const.HALF_HALF_PRICE
      half.onlyLunch = vm.$const.HALF_HALF_SET.NORMAL
      half.columnDisplay = 2
      image = vm.$const.HALF_HALF_IMAGES.NORMAL_2
    } else {
      half.uuid = vm.$const.HALF_HALF_LUNCH_UUID
      half.name = vm.$t('half_lunch_name')
      half.marketing_description = vm.$t('half_lunch_description')
      half.price = vm.$const.HALF_HALF_LUNCH_PRICE
      half.display_price = vm.$const.HALF_HALF_LUNCH_PRICE
      half.onlyLunch = vm.$const.HALF_HALF_SET.LUNCH
      image = vm.$const.HALF_HALF_IMAGES.LUNCH_2
      half.columnDisplay = 2
    }
  }

  half.image = {
    desktop_thumbnail: image,
    mobile_thumbnail: image
  }

  return half
}
</script>
<style>
#modal-menu .modal-action {
  padding: 8px !important;
}

.fs-30 {
  font-size: 30px;
}

.pr-7 {
  padding-right: 7px;
}

.pl-7 {
  padding-left: 7px;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media only screen and (max-width: 767px) {
  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }
}

.watermark img {
  width: 320px;
}

@media only screen and (min-width: 640px) {
  .watermark img {
    width: 400px;
  }
}
</style>
